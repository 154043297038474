.slick-dots li.slick-active button:before {
  color: #00b050;
}

.slick-dots li button:before {
  font-size: 12px;
}

.slick-dots li {
  margin: 0;
  width: 16px;
  height: 16px;
}

.slick-slider {
  padding-bottom: 16px;
  @media (max-width: 600px) {
    padding-bottom: 32px;
  }
}
