.experience-description > p:first-of-type {
  margin-top: 8px;
}

.experience-description ul {
  margin: 0;
  padding: 0;
}

.experience-description ul li {
  margin: 8px 32px 8px 20px;
}
